import React from 'react';
import { ReactComponent as HeaderLogo } from '../assets/headerLogo.svg';
import left from './1.png'; 
import center from './2.png'; 
import rigth from './3.png'; 

import '../App.scss';
import styles from './styles.module.scss'

const DeleteAccount = () => {
  return (
    <div className="container">
      <main className='main-frame'>
        <header className="header">
          <div className="header-left">
            <HeaderLogo/>
            <div className="logo">FoodZee</div>
          </div>

          <div className="header-right">
            <button className="contactButton">Support@foodzee.tech</button>
          </div>
        </header>
        <div className={styles.mainTitle}>Account Deletion</div>
        <main className="mainContent" style={{ alignItems: 'flex-start', marginBottom: 10}}>
          <div className={styles.card}>
            <div className={styles.cardTitle}><span>Step 1</span>Go to settings</div>
            <img src={left} className={styles.image} />
          </div>
          <div className={styles.card}>
           <div className={styles.cardTitle}><span>Step 2</span>Select Delete account</div>
           <img src={center} className={styles.image} />
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}><span>Step 3</span>Click Delete account</div>
            <img src={rigth} className={styles.image} />
          </div>
        </main>

        <footer className="footer">
          <div className="footer-left">
            <span className="footer-left-first">POFKARIO HOLDINGS LIMITED</span>
            <span className="footer-left-second">Thessalonikis 43, office/flat 301 Thessaloniki Court, 3025 Limassol, Cyprus</span>
          </div>

          <div className="footer-right">
            <span className="footer-right-first">support@foodzee.tech</span>
          </div>
        </footer>
      </main>
    </div>
  );
};

export default DeleteAccount;