import styles from './styles.module.scss';

const TermsOfUse = () => {
  return (
    <div className={styles.container}>
      <div>
        <p className={styles.footnote}>Effective date: November 27, 2024</p>
        <h1>Terms of Use</h1>
      </div>

      <div>
        These Terms of Use (the “<b>Terms</b>”) apply to your access and use of
        our mobile application “FOODZEE” (the “<b>App</b>”), our websites
        (including but not limited to{' '}
        <a href='https://foodzee.tech/'>foodzee.tech</a> ), our blog, and all
        related services, features, and content (collectively the “
        <b>Services</b>”) provided by POFKARIO HOLDINGS LIMITED, a company
        registered in Cyprus with registration no. ΗΕ 467843 and registered
        address Thessalonikis 43, office/flat 301 Thessaloniki Court, 3025
        Limassol, Cyprus (collectively referred to as “<b>Company</b>
        ”, “<b>we</b>”, or “<b>us</b>”). The Terms are not entered into with
        Apple, Inc. and any of its subsidiaries, or Google, Inc. and any of its
        subsidiaries, or any other entity as may apply. If you have any
        questions about the Terms or our Services, please contact us at{' '}
        <a href='mailto:support@foodzee.tech'>support@foodzee.tech.</a>
      </div>

      <div>
        Please read the Terms carefully. By accessing and/or using our Services,
        you acknowledge that you accept and agree to be bound by the Terms. If
        you do not agree to these Terms, including the mandatory arbitration
        provision and class action waiver in Section 18 (Dispute Resolution by
        Negotiation and Binding Arbitration Agreement), do not use FOODZEE. Your
        continued use of FOODZEE will confirm your acceptance of these Terms.
      </div>

      <div>
        <b>
          IF YOU DO NOT AGREE TO THE TERMS, YOU MAY NOT ACCESS AND/OR USE THE
          SERVICES.
        </b>
      </div>

      <div>
        <p>
          <b>1. Changes to the Terms</b>
        </p>
        <p>
          We may modify the Terms from time to time. If the changes affect your
          rights, we will notify you in advance by email or through the
          Services, or by presenting you with a new version of the Terms for you
          to accept. Your continued use of the Services after the effective date
          of an updated version of the Terms will indicate your acceptance of
          the Terms as modified.
        </p>
        <p>
          We reserve the right to introduce minor changes to the Terms that do
          not materially affect your rights (e.g., technical corrections or
          description of a new feature) without notice.
        </p>
      </div>

      <div>
        <p>
          <b>2. Important Disclaimers</b>
        </p>

        <div>
          You should <b>NOT</b> use the App under these conditions:
        </div>
        <ul>
          {[
            'If you are pregnant or breastfeeding',
            'If you are underweight (BMI < 18.5)',
            'Under 18 years old',
            'Over 80 years old',
            'Have a diagnosed eating disorder',
            'Have type 1 diabetes',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>
          If you suffer from a medical condition, use medications, are over the
          age of 70, or intend to follow any diet plan, please consult your
          doctor before starting.
        </p>
        <p>
          You understand that the Services are not medical or healthcare
          services and that no physician-patient relationship exists.
        </p>
        <p>
          The Services may not be appropriate for all persons and are not a
          substitute for professional healthcare services. The Services are
          intended only as a tool, which may be useful in achieving your overall
          health, fitness, and wellness goals. You acknowledge that your diet
          and exercise activities involve risks, which may involve the risk of
          bodily injury or death, and that you assume those risks. Before
          accessing or using the Services, you agree to release and discharge
          the Company from any and all action, known or unknown, arising out of
          your use of the Services.
        </p>
        <p>
          Please consult with a licensed physician or other qualified healthcare
          providers before making any decisions or taking any actions that may
          affect your health and safety or that of your family or fetus.
        </p>
        <p>
          Never disregard professional medical advice or delay in seeking it
          because of something you have read using our Services.
        </p>
        <p>
          Always consult with your healthcare professional if you have any
          questions or concerns about your health or condition or experience any
          changes in your condition or health status. If you think you have a
          medical emergency, call emergency services or go to the nearest open
          emergency room immediately.
        </p>
        <p>
          Developments in medical research may impact the wellness
          recommendations that appear on the Services. No assurance can be given
          that the recommendations contained in the Services will always include
          the most recent medical findings or developments.
        </p>
        <p>
          We make no guarantees concerning the level of success you may
          experience, and you accept that the underlying risks will differ for
          each individual. The testimonials and examples that are provided on
          the Services are for reference purposes only and are not intended to
          represent or guarantee that anyone will achieve the same or similar
          results. There is no assurance that examples of past fitness results
          can be duplicated in the future. We cannot guarantee your future
          results and/or success, nor can we guarantee that you will maintain
          the results you experience if you do not continue following the
          personalized meal plans.
        </p>
        <p>
          In addition to all other limitations and disclaimers in the Terms, the
          Company disclaims any liability or loss in connection with the content
          provided through the Services.
        </p>
      </div>

      <div>
        <p>
          <b>3. Subscriptions</b>
        </p>
        <p>
          All regular subscriptions automatically renew at the end of your
          then-current subscription period unless you turn off auto-renewal or
          cancel your subscription at least 24 hours before the subscription
          period ends. You can cancel your regular subscription at any time, and
          you will still be able to access the premium features until the end of
          the subscription period. If you cancel your subscription during your
          subscription period, you will not be refunded for the unused period.
        </p>
        <p>
          We may offer commitment subscriptions providing you with the option to
          subscribe to FOODZEE for a predetermined duration (e.g., 3 months, 10
          months) at a reduced price per month. After the commitment period
          expires, the commitment subscription converts to a regular
          subscription which auto-renews monthly at the monthly subscription
          price applicable at the time of renewal. Generally, commitment
          subscriptions cannot be canceled until the commitment period expires,
          unless one of the exceptions mentioned in our Refund Policy applies.
          If none of the exceptions apply, you will need to pay a non-refundable
          cancellation fee of $29.99 (or its equivalent in any other currency)
          to cancel before expiration of the commitment period.
        </p>
        <div>
          For the purposes of this Section, the term “<b>subscription(s)</b>”
          covers both regular subscriptions and commitment subscriptions, unless
          the contrary is specified.
        </div>
        <p>
          Subscriptions auto-renew even if you are not actively using the App.
        </p>
        <div>
          If you have any difficulties, concerns, or questions about your
          subscription, please contact us at{' '}
          <a href='mailto:support@foodzee.tech'>support@foodzee.tech.</a>
        </div>
      </div>

      <div>
        <div>
          <b>3.1. FOODZEE subscription. </b>We offer subscriptions that grant
          you access to special features and content, including, but not limited
          to, personalized meal plans and AI chat assistance. You agree that
          your purchases are not contingent on the delivery of any future
          functionality or features, or dependent on any oral or written public
          comments made by the Company regarding future functionality or
          features.
        </div>
      </div>
      <div>
        <div>
          <b>3.2. Billing. </b>You may purchase a FOODZEE regular subscription
          either through the Apple App Store/Google Play Store or through
          FOODZEE’s website. Commitment subscriptions can only be purchased
          through FOODZEE’s website. If you choose to purchase a regular
          subscription via the stores, payment will be charged to your
          credit/debit card through your App Store or Google Play account after
          you choose one of our regular subscriptions and confirm your purchase.
          Otherwise, the payment will be charged to your credit/debit card or
          PayPal account after you choose one of our subscriptions on the
          website and confirm your purchase.
        </div>
        <p>
          When the subscription auto-renews, the payment is charged at the
          beginning of each billing period to the payment method you used during
          the initial subscription purchase. You authorize us to charge the
          applicable subscription fees to the payment method that you used.
        </p>
      </div>
      <div>
        <div>
          <b>3.3. Intro offers. </b>We may offer users an intro offer period as
          part of the chosen subscription to experience the premium features at
          a reduced price. The intro offer period is considered a part of the
          subscription period regarding, among others, the right to cancel the
          subscription within the applicable cooling off period (if any). During
          the intro offer, you may either cancel your subscription no later than
          24 hours before the intro offer period ends, or you may continue using
          the premium subscription at its normal price, and we will begin to
          charge you accordingly.
        </div>
      </div>

      <div>
        <div>
          <b>3.4. Renewal and Cancellation. </b>Your paid subscription will
          automatically renew at the end of the applicable subscription period.
          To avoid unexpected charges, you can:
        </div>
        <ul>
          {[
            'Turn off auto-renew in-app through your account settings, or',
            'Cancel your regular subscription no later than 24 hours before the end of the subscription period, or your commitment subscription no later than 24 hours before the end of the commitment period. Your subscription can be canceled at any time in-app through your account settings.',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <div>
          If your subscription was purchased through our website: you may cancel
          automatic renewals of such subscription at any time prior to the
          renewal date in your account settings or by emailing us at{' '}
          <a href='mailto:support@foodzee.tech'>support@foodzee.tech.</a>
        </div>
        <p>
          If you purchased a regular subscription through the Apple App Store:
          you may cancel automatic renewals by selecting “Manage App
          Subscriptions” in your Apple Account settings and selecting the
          subscription you want to modify, or otherwise in accordance with the
          current functionality of that platform.
        </p>
        <p>
          If you purchased a regular subscription through the Google Play Store:
          you may cancel automatic renewals in the account settings under the
          “Subscriptions” tab in the Google Play app, or according to the
          then-current process outlined by Google Play, or otherwise in
          accordance with the current functionality of that platform.
        </p>
        <p>
          Please note that your purchased subscription does not cancel
          automatically if you delete the mobile application. You should cancel
          the subscription before the deletion of the mobile application.
        </p>
        <div>
          Should you have any questions or concerns, please contact our support
          team at <a href='mailto:support@foodzee.tech'>support@foodzee.tech</a>{' '}
          for instructions on how to cancel your subscription.
        </div>
      </div>

      <div>
        <div>
          <b>3.5. Refunds. </b>Your refund options depend on the way you have
          purchased your subscription.
        </div>
        <ul>
          {[
            'If your subscription was purchased through our website: Generally, your purchases are non-refundable. However, sometimes we provide refunds: e.g., upon meeting certain criteria or where the applicable laws of your jurisdiction provide you with a cooling off period. For further information, please refer to our Refund Policy.',
            'If you purchased a regular subscription through the Apple App Store or Google Play Store: Subscriptions purchased via the App Store are subject to the App Store’s refund policies, while subscriptions purchased via the Play Store are subject to the Play Store’s refund policies. This means that we cannot grant refunds for such subscriptions. To claim a refund for a subscription purchased via the App Store or Google Play, please contact App Store support or Google Play support accordingly.',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
      <div>
        <div>
          <b>3.6. Changes. </b>We may from time to time make changes to our
          subscription plans, including recurring subscription fees, and will
          communicate any price changes to you in advance. Price changes will
          take effect at the start of the next subscription period following the
          date of the price change and, by continuing to use our subscription
          after the price change takes effect, you will have accepted the new
          price. If you don’t agree to a price change, simply unsubscribe from
          the applicable subscription no later than 24 hours before the end of
          the subscription term to avoid getting charged any further.
        </div>
      </div>
      <div>
        <div>
          <b>3.7. Taxes. </b>Tax rates or other fees are based on the rates
          applicable at the time of your charge. These amounts can change over
          time depending on local tax requirements in your territory, city,
          state, or country. Any change in the applicable tax rate will be
          automatically applied based on the account information you have
          provided.
        </div>
      </div>
      <div>
        <p>
          <b>4. Your Privacy</b>
        </p>
        <div>
          We care about your privacy. Please read our Privacy Policy available
          at <a href='https://foodzee.tech/privacy'>foodzee.tech/privacy.</a>
        </div>
        <p>
          The Privacy Policy is an integral part of the Terms. To the extent
          there is an inconsistency between the Terms and the Privacy Policy,
          the Terms shall govern.
        </p>
        <p>
          The Services are not intended or designed to attract children under
          the age of 18. We do not collect personally identifiable information
          from any person we know is a child under the age of 18.
        </p>
        <div>
          If you are aware of anyone who does not comply with these limitations,
          please contact us at{' '}
          <a href='mailto:support@foodzee.tech'>support@foodzee.tech</a>, and we
          will take steps to delete their account.
        </div>
      </div>

      <div>
        <p>
          <b>5. Registration and Eligibility</b>
        </p>
      </div>
      <div>
        <div>
          <b>5.1. </b>To use the Services, you may be required to create an
          account and to provide certain personal information, which may
          include, among others, your name, gender, birth date, and e-mail
          address. You agree that you will supply accurate and complete
          information to the Company, and that you will update that information
          promptly after it changes. Otherwise, the Services may not operate
          correctly, and we may not be able to contact you with important
          notices.
        </div>
      </div>
      <div>
        <div>
          <b>5.2. </b>You may not create the account with a name that you don’t
          have the right to use, or another person’s name with the intent to
          impersonate that person.
        </div>
      </div>
      <div>
        <div>
          <b>5.3. </b>To create an account and access the Services, you must be
          at least 18 years old and not barred from using FOODZEE under
          applicable law. You hereby represent and warrant to the Company that
          you meet such qualifications.
        </div>
      </div>
      <div>
        <div>
          <b>5.4. </b>You will not share your account or password with anyone,
          and you must protect the security of your account and your password.
          You’re responsible for any activity associated with your account.
        </div>
      </div>
      <div>
        <div>
          <b>5.5. </b>The Company has the right to suspend or terminate your
          account, with or without notice to you, if you breach the Terms.
        </div>
      </div>

      <div>
        <p>
          <b>6. Your Use of the Services</b>
        </p>
        <p>You will only use the Services:</p>
        <ul>
          {[
            'For your own personal, non-commercial use, and',
            'Not on behalf, or for the benefit, of any third party, and',
            'Only in a manner compliant with all applicable laws.',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>
          We can’t and won’t be responsible for your use of the Services in a
          way that breaks the law.
        </p>
        <div>
          As a condition to using our Services, you agree that you{' '}
          <b>SHALL NOT:</b>
        </div>
        <ul>
          {[
            'Sell, rent, lease, loan, sublicense, or otherwise transfer the rights or obligations from the Terms;',
            'Modify, reverse engineer, decompile, or disassemble the Services;',
            'Copy, adapt, alter, modify, translate, or create derivative works of the Services without the written authorization of the Company;',
            'Permit other individuals to use the Services, including but not limited to shared use via a network connection (unless allowed by the Terms);',
            'Bypass or disable any technology that protects the intellectual property rights of the Company;',
            'Use the Services in an attempt to, or in conjunction with any device, program, or service designed to, circumvent technological measures employed to control access to, or the rights in, a content file or other work protected by the copyright laws of any jurisdiction;',
            'Use or access the Services to compile data in a manner that is used or usable by a competitive product or service;',
            'Use your account to advertise, solicit, or send any business advertisements, including chain letters, spam emails, or repetitive messages to anyone;',
            'Use your account for illegal purposes;',
            'Upload and/or transmit any communications that infringe or violate the rights of any party;',
            'Upload media of any kind that contain expressions of hate, abuse, offensive images or conduct, obscenity, pornography, sexually explicit content, or any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with the Terms; or',
            'Upload any material that contains software viruses, or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or any of our Services.',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>
          If you feel threatened or believe someone else is in danger, contact
          your local law enforcement agency immediately. If you think you may
          have a medical emergency, call your doctor or emergency services
          immediately.
        </p>
      </div>
      <div>
        <p>
          <b>7. Export and Economic Sanctions Control</b>
        </p>
        <div>
          The software that supports the Services may be subject to U.S. export
          and re-export control laws and regulations, including the Export
          Administration Regulations (“<b>EAR</b>”) maintained by the U.S.
          Department of Commerce, trade and economic sanctions maintained by the
          Treasury Department’s Office of Foreign Assets Control (“<b>OFAC</b>
          ”), and the International Traffic in Arms Regulations (“<b>ITAR</b>”)
          maintained by the Department of State.
        </div>
        <p>You represent and warrant that you are:</p>
        <ul>
          {[
            'Not located in any country or region that is subject to a U.S. government embargo, and',
            'Not a denied party as specified in the regulations listed above.',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>
          You agree to comply with all U.S. and foreign export laws and
          regulations to ensure that neither the Services nor any technical data
          related to them, nor any direct product of the Services is exported or
          re-exported directly or indirectly in violation of or used for any
          purposes prohibited by such laws and regulations.
        </p>
      </div>
      <div>
        <p>
          <b>8. Limited License to the Services</b>
        </p>
      </div>
      <div>
        <div>
          <b>8.1. </b>We grant you a personal, worldwide, revocable,
          non-transferable, and non-exclusive license (without the right to
          sublicense) to access and use the Services for personal and
          non-commercial purposes in accordance with the Terms.
        </div>
      </div>
      <div>
        <div>
          <b>8.2. </b>You may not copy, store, modify, distribute, transmit,
          perform, reproduce, publish, license, create derivative works from,
          transfer, or sell any text, graphics, logos, and other
          source-identifying symbols, designs, icons, images, or other
          information, software, or code obtained from the Services without
          prior express written permission from the Company, and such consent
          may be withheld for any or no reason.
        </div>
      </div>
      <div>
        <div>
          <b>8.3. </b>You further agree not to download, display, or use any
          content on the Services that are provided by the Company or its
          licensors for use in any publications, in public performances, on
          websites other than the Services for any other commercial purpose, in
          connection with products or services that are not those of the
          Company, or in any other manner that is likely to cause confusion
          among consumers, that disparages or discredits the Company and/or its
          licensors, that dilutes the strength of the Company or its licensor’s
          property, or that otherwise infringes the Company’s or its licensors’
          intellectual property rights.
        </div>
      </div>
      <div>
        <div>
          <b>8.4. </b>You further agree not to misuse any content published by
          the Company or third-party content that appears using the Services.
        </div>
      </div>
      <div>
        <div>
          <b>8.5. </b>All rights, title, and interest in and to the Services not
          expressly granted through the Terms are reserved by the Company. If
          you wish to use our software, title, trade name, trademark, service
          mark, logo, domain name, and/or any other identification with notable
          brand features or other content owned by the Company, you must first
          obtain our written permission. Permission requests may be sent to{' '}
          <a href='mailto:support@foodzee.tech'>support@foodzee.tech</a>
        </div>
      </div>
      <div>
        <div>
          <b>8.6. </b>To avoid any doubt, the Company owns all the text, images,
          photos, audio, video, location data, software, code, and all other
          forms of data or communication that the Company creates and makes
          available through the Services, including but not limited to visual
          interfaces, interactive features, graphics, design, and the
          compilation of aggregate user review ratings and all other elements
          and components of any of the Services (collectively referred to herein
          as the “<b>Company’s Content</b>”). Except as expressly and
          unambiguously provided herein, we do not grant you any express or
          implied rights, and all rights in and to the Services and the
          Company’s Content are retained by us.
        </div>
      </div>
      <div>
        <p>
          <b>9. Use at Your Own Risk</b>
        </p>
        <p>
          Our goal is to help make certain wellness and health-related
          information more readily available and useful to you; however, the
          Services cannot and do not guarantee health-related improvements or
          outcomes. Your use of the Services and any information, predictions,
          or suggestions provided through the Services are at your sole risk. We
          make no representation or warranty of any kind as to the accuracy of
          data, information, estimates, and predictions that we may provide to
          you through the Services, and you agree and understand that the
          Services are not intended to match or serve the same purpose as a
          medical or scientific device.
        </p>
      </div>

      <div>
        <p>
          <b>10. Account Security</b>
        </p>
        <p>
          You are responsible for taking all reasonable steps to ensure that no
          unauthorized person shall have access to your account.
        </p>
        <p>It is your sole responsibility to:</p>
        <ul>
          {[
            'Control the dissemination and use of sign-in names and passwords;',
            'Authorize, monitor, and control access to and use of your account;',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
          <li>
            Promptly inform the Company if you believe your account has been
            compromised or if there is any other reason you need to deactivate
            your account. Send us an email at{' '}
            <a href='mailto:support@foodzee.tech'>support@foodzee.tech.</a>
          </li>
        </ul>
        <p>
          You grant the Company and all other persons or entities involved in
          the operation of the Services the right to transmit, monitor,
          retrieve, store, and use your information in connection with the
          operation of the Services. The Company cannot and does not assume any
          responsibility or liability for any information you submit, or your or
          third parties’ use or misuse of information transmitted or received
          using the Services, and shall not be responsible for any losses
          arising out of the unauthorized use of your account or information
          resulting from you not following these rules.
        </p>
      </div>

      <div>
        <p>
          <b>11. Warranty Disclaimer</b>
        </p>
      </div>
      <div>
        <div>
          <b>11.1. </b>The Company controls and operates the Services from
          various locations and makes no representation that the Services are
          appropriate or available for use in all locations. The Services or
          certain features may not be available in your location or may vary
          across locations.
        </div>
      </div>
      <div>
        <div>
          <b>11.2. </b>THE SERVICES ARE PROVIDED “AS IS”, “AS AVAILABLE” AND ARE
          PROVIDED WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND,
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
          WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS
          FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF
          PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED,
          SAVE TO THE EXTENT REQUIRED BY LAW.
        </div>
      </div>
      <div>
        <div>
          <b>11.3. </b>The Company, and its directors, employees, agents,
          representatives, suppliers, partners, and content providers do not
          warrant that:
        </div>
        <ul>
          {[
            'The Services will be secure or available at any particular time or location;',
            'Any defects or errors will be corrected;',
            'Any content or software available at or through the Services is free of viruses or other harmful components; or',
            'The Services will meet your requirements.',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
      <div>
        <div>
          <b>11.4. </b>You use the Services at your own risk. You will be solely
          responsible for any damage to your computer system or mobile device or
          loss of data that results from the use of any such material.
        </div>
      </div>
      <div>
        <div>
          <b>11.5. </b>No guarantee of specific results. We do not guarantee and
          do not promise any specific results from your use of our Services. You
          agree to bear the risks of interruption of the Services for any
          technical reasons.
        </div>
      </div>
      <div>
        <div>
          <b>11.6. </b>No Medical Advice. The Company makes no representations
          or warranties and, to the fullest extent permitted by law, expressly
          disclaims any and all liability relating to your reliance on the
          statements or other information offered or provided within or through
          the Services. If you have specific concerns or a situation arises in
          which you require professional or medical advice, you should consult
          with an appropriately trained and qualified specialist.
        </div>
      </div>
      <div>
        <div>
          <b>11.7. </b>Since some states/countries do not allow limitations on
          implied warranties, the above limitations may not apply to you.
        </div>
      </div>

      <div>
        <p>
          <b>12. Limitation of Liability</b>
        </p>
      </div>

      <div>
        <div>
          <b>12.1. </b>In no event shall the Company, its officers, directors,
          agents, affiliates, employees, advertisers, or data providers be
          liable for any indirect, special, incidental, consequential, or
          punitive damages (including but not limited to loss of use, loss of
          profits, or loss of data) whether in an action in contract, tort
          (including but not limited to negligence), equity, or otherwise,
          arising out of or in any way connected with the use of the Services.
        </div>
      </div>
      <div>
        <div>
          <b>12.2. </b>In no event will the Company’s total liability arising
          out of or in connection with the Terms or from the use of or inability
          to use the Services exceed the amounts you have paid to the Company
          for use of the Services or $100 if you have not had any payment
          obligations to the Company, as applicable.
        </div>
      </div>
      <div>
        <div>
          <b>12.3. </b>Some jurisdictions do not allow the exclusion or
          limitation of liability, so the above limitations may not apply to
          you. Nothing in the Terms excludes or limits our liability to you
          where it would be unlawful to do so. Where any provision is expressed
          to exclude or limit liability to a greater extent than permitted by
          applicable law, that provision shall be deemed to only exclude or
          limit our liability to the maximum extent permitted by applicable law.
        </div>
      </div>
      <div>
        <div>
          <b>12.4. </b>Neither the Company, nor any third party mentioned on any
          of the Services, is liable for any personal injury, including death,
          caused by your use or misuse of the Services.
        </div>
      </div>
      <div>
        <div>
          <b>12.5. </b>If you are a California resident, you hereby waive
          California Civil Code Section 1542, which states, “a general release
          does not extend to claims that the creditor or releasing party does
          not know or suspect to exist in his or her favor at the time of
          executing the release and that, if known by him or her, would have
          materially affected his or her settlement with the debtor or released
          party.”
        </div>
      </div>

      <div>
        <p>
          <b>13. Use of Mobile Devices</b>
        </p>
        Your carrier’s normal rates and fees, such as text messaging and data
        charges, will still apply if you are using the Services on a mobile
        device.
      </div>

      <div>
        <p>
          <b>14. Third-Party Services</b>
        </p>
        <div>
          The Services may give you access to as to third-party websites, apps,
          or other products or services (“<b>Third Party Services</b>”). The
          Company does not control Third Party Services in any manner and,
          accordingly, does not assume any liability associated with such Third
          Party Services. You need to take appropriate steps to determine
          whether accessing a Third Party Service is appropriate, and to protect
          your personal information and privacy in using any such Third Party
          Services. Your aing to or use of any Third Party Services other than
          our Services is at your own risk. The Company’s inclusion of as to
          Third Party Services does not imply any endorsement of any kind by the
          Company of the material located on or aed to by such Third Party
          Services and should not be deemed as such by any user of our Services.
          The Company disclaims any responsibility for the products or services
          offered or the information contained on any Third Party Services. You
          need to take appropriate steps to determine whether accessing a Third
          Party Service is appropriate, including protecting your personal
          information and privacy in using any such Third Party Services and
          complying with relevant agreements.
        </div>
        <p>
          You shall not a to our website, App, content, or Services in a way
          that is: (i) illegal, (ii) suggests any form of association, approval,
          or endorsement with or by us where none exists, (iii) damages our
          reputation or takes advantage of it, or (iv) is unfair.
        </p>
      </div>

      <div>
        <p>
          <b>15. Your Feedback</b>
        </p>
        <p>
          We welcome your feedback about the Services. Unless otherwise
          expressly declared, any communications you send to us are deemed to be
          submitted on a non-confidential basis. You agree that we may decide to
          publicize such content at our own discretion. You agree to authorize
          us to make use of such content for free, and revise, modify, adjust,
          and change contextually, or make any other changes as we deem
          appropriate.
        </p>
      </div>

      <div>
        <p>
          <b>16. Enforcement Rights</b>
        </p>
        <p>
          We are not obliged to monitor your access or use of the Services;
          however, we reserve the right to do so for purposes of operating and
          maintaining the Services, ensuring your compliance with the Terms, and
          complying with applicable legal requirements. We reserve the right
          (but are not required) to remove or disable any content posted to the
          Services or access to Services at any time without notice and at our
          sole discretion if we determine that your content or use of the
          Services is objectionable or in violation of the Terms.
        </p>
        <p>
          We may refuse service, close accounts, and change eligibility
          requirements at any time.
        </p>
        <p>
          The Company has no liability or responsibility to users of our
          Services or any other person or entity for the performance or
          nonperformance of any of the above.
        </p>
      </div>

      <div>
        <p>
          <b>17. Indemnity</b>
        </p>
        <p>
          You agree to defend, indemnify, and hold the Company, its officers,
          directors, employees, agents, licensors, and suppliers harmless from
          and against any claims, actions, or demands, liabilities, and
          settlements including without limitation, reasonable legal and
          accounting fees, resulting from, or alleged to result from, your use
          of the Services or your violation of the Terms.
        </p>
        <p>
          The Company reserves the right, at your expense, to assume the
          exclusive defense and control of any matter for which you are required
          to indemnify us, and you agree to cooperate with us for defense of
          these claims. You agree not to settle any matter without the prior
          written consent of the Company. The Company will use reasonable
          efforts to notify you of any such claim, action, or proceeding upon
          becoming aware of it.
        </p>
      </div>

      <div>
        <p>
          <b>
            18. Dispute Resolution by Negotiation and Binding Arbitration
            Agreement
          </b>
        </p>
      </div>

      <div>
        <p>
          <b>18.1. Initial Dispute Resolution via Negotiation</b>
        </p>
        <p>
          We strive to resolve disputes amicably and efficiently. You hereby
          agree that before initiating arbitration proceedings against us, we
          will negotiate in good faith and try to resolve informally any claim
          or demand you might have. The negotiations can take place without
          limitation via emails, calls, video calls or physical meetings.
        </p>
        <div>
          If you have any dispute against us, you agree that before taking any
          formal action, you will contact us at{' '}
          <a href='mailto:support@foodzee.tech'>support@foodzee.tech</a>, and
          provide a brief, written description of the dispute and your contact
          details. We will use our best efforts to settle any dispute, claim,
          question, or disagreement directly through consultation between us.
        </div>
        <div>
          <b>
            Engaging in this informal dispute resolution is a condition
            precedent that must be fulfilled before commencing arbitration, and
            the Arbitrator shall dismiss any arbitration demand filed before the
            completion of an informal dispute resolution.
          </b>{' '}
          Therefore, you agree that you will only be able to initiate
          arbitration proceedings if your dispute or claim is not amicably
          resolved within <b>45 days</b> from the date you informed FOODZEE
          about it. The only exception to this timeframe is if FOODZEE informs
          you in writing prior to the expiration of the 45-day period that it is
          unable or unwilling to settle your claim or dispute, in which case you
          can initiate arbitration proceedings immediately.
        </div>
        <p>
          Any applicable statute of limitations and/or any filing fee deadlines
          shall be paused while we engage in the informal dispute resolution
          process required by this paragraph.
        </p>
      </div>

      <div>
        <p>
          <b>18.2. Binding Arbitration Agreement</b>
        </p>
        <div>
          You and FOODZEE agree to resolve any disputes, claims, or
          controversies arising out of or relating to these Terms, or your use
          of the Services, including the validity, enforceability, or scope of
          this arbitration agreement (collectively “<b>Disputes</b>“) through
          binding arbitration, <b>except to the extent that:</b>
        </div>
        <ul>
          {[
            'Any applicable law prohibits the exclusive use of arbitration for dispute resolution; or',
            'Both parties agree in writing to resolve the Dispute in court.',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>
          <b>
            For both of the above exceptions, the governing law and jurisdiction
            in Section 19 will apply.
          </b>
        </p>
      </div>

      <div>
        <div>
          <b>Agreement to Arbitrate. </b>By using or accessing our Services, you
          agree to resolve any Disputes exclusively through final and binding
          arbitration. Nevertheless, each party retains the right to seek
          injunctive or other equitable relief in a court of competent
          jurisdiction to prevent the actual or threatened infringement,
          misappropriation or violation of a party’s copyrights, trademarks,
          trade secrets, patents or other intellectual property rights. This
          arbitration agreement is governed by the Federal Arbitration Act
          (“FAA”). This agreement to arbitrate is intended to be broadly
          interpreted.
        </div>
      </div>

      <div>
        <p>
          <b>Arbitration Rules. </b>The arbitration will be administered by the:
        </p>
        <ul>
          <li>
            American Arbitration Association (AAA) under its Consumer
            Arbitration Rules for <b>US users</b>, or
          </li>
          <li>
            International Centre for Dispute Resolution (ICDR) under its
            International Arbitration Rules for <b>non-US users.</b>
          </li>
        </ul>
        <div>
          The AAA and ICDR rules are available at{' '}
          <a href='http://www.adr.org/'>www.adr.org</a>, and for the purposes of
          this Section they are collectively referred to as the “
          <b>applicable rules</b>”. Any rules or procedures governing or
          permitting class actions under the applicable rules are hereby
          excluded and are not enforceable.
        </div>
      </div>

      <div>
        <div>
          <b>Initiating Arbitration. </b>To initiate arbitration, you must send
          us a notification requesting arbitration and describing the Dispute to{' '}
          <a href='mailto:support@foodzee.tech'>support@foodzee.tech</a>. For
          FOODZEE to initiate arbitration, we must send you a notification
          requesting arbitration and describing the Dispute to the email address
          you used to subscribe to our Services or in any prior communication
          with us. The arbitration will be conducted by a single arbitrator
          selected in accordance with the applicable rules.
        </div>
      </div>

      <div>
        <div>
          <b>Arbitration Procedure and Location. </b>The arbitration will be
          conducted by a single arbitrator selected in accordance with the
          applicable rules. If the parties are unable to agree upon an
          arbitrator within 15 days of delivery of the notification requesting
          arbitration, then the appointment of the arbitrator will be done in
          accordance with the AAA’s rules (for US users) or the ICDR’s rules
          (for non-US users).
        </div>
        <p>
          If your claim does not exceed $10,000, then the arbitration will be
          conducted solely on the basis of documents you and FOODZEE submit to
          the arbitrator, unless the arbitrator determines that a hearing is
          necessary. If your claim exceeds $10,000, your right to a hearing will
          be determined by the applicable rules. Subject to the applicable
          rules, the arbitrator will have the discretion to direct a reasonable
          exchange of information by the parties, consistent with the expedited
          nature of the arbitration.
        </p>
        <p>
          For US users, the arbitrator will apply the substantive law of the
          state of Delaware; and for non-US users the substantive law of your
          country of residence, without regard to its conflict of laws
          principles. For US users, the arbitration will be conducted in the
          county where you reside, unless you and FOODZEE agree otherwise. For
          non-US users, the arbitration will be conducted in a location
          reasonably convenient to both parties, unless you and FOODZEE agree
          otherwise. In the event the parties cannot agree on the location of
          the arbitration, the selected arbitrator shall have authority to
          decide the place where the arbitration shall be conducted.
        </p>
      </div>

      <div>
        <div>
          <b>Arbitration Fees. </b>The payment of all filing, administration,
          and arbitrator fees will be governed by the AAA’s rules (for US users)
          or the ICDR’s rules (for non-US users).
        </div>
      </div>

      <div>
        <div>
          <b>Authority of Arbitrator and Award. </b>The arbitrator shall have
          the authority to grant motions dispositive of all or part of any
          claim. The arbitrator shall have the authority to award monetary
          damages and to grant any non-monetary remedy or relief available to an
          individual under applicable law, the applicable rules, and these
          Terms; provided however that the{' '}
          <b>
            arbitrator’s awarded damages must be consistent with the terms of
            the “Limitation of liability” section above as to the types and the
            amounts of damages for which a party may be held liable
          </b>
          . The arbitrator shall issue a written award and statement of decision
          describing the essential findings and conclusions on which the award
          is based. The arbitrator has the same authority to award relief on an
          individual basis that a judge in a court of law would have. The award
          of the arbitrator is final and binding upon you and FOODZEE, and
          judgment of the arbitration award may be entered in any court having
          jurisdiction thereof.
        </div>
      </div>

      <div>
        <div>
          <b>No Class Actions. </b>You and FOODZEE agree that each may bring
          claims against the other only in your or its individual capacity and
          NOT as a plaintiff or class member in any purported class or
          representative proceeding. Unless both you and FOODZEE agree
          otherwise, the arbitrator may not consolidate more than one person’s
          claims, and may not otherwise preside over any form of a
          representative or class proceeding. Further, you acknowledge and agree
          that you and FOODZEE are each waiving the right to a trial by jury.
        </div>
      </div>

      <div>
        <div>
          <b>Opt-Out. </b>You may opt out of this arbitration agreement. If you
          do so, neither you nor FOODZEE can force the other to arbitrate. To
          opt out, you must notify us in writing no later than 30 days from the
          date you first used any of our Services. Your notice must include your
          name, your FOODZEE username (if any), the email address you used to
          set up your FOODZEE account (if you have one), and a clear statement
          that you want to opt out of this arbitration agreement. You must send
          your opt-out notice to:{' '}
          <a href='mailto:support@foodzee.tech'>support@foodzee.tech.</a>
        </div>
      </div>

      <div>
        <div>
          <b>Changes. </b>Changes to this section will become effective on the
          30th day after posting an updated version of the Terms in our website
          (see “Effective Date” above), and will apply prospectively only to any
          claims arising after the 30th day.
        </div>
      </div>
      <div>
        <div>
          <b>Enforceability. </b>If the waiver of class actions set forth in
          this section is found to be illegal or unenforceable as to all or some
          parts of a Dispute, then those parts shall not be resolved through
          arbitration but will proceed in a court of competent jurisdiction,
          with the remaining parts proceeding in arbitration. If any other
          provision of this section is found to be illegal or unenforceable,
          that provision will be severed with the remainder of this section
          remaining in full force and effect.
        </div>
      </div>

      <div>
        <p>
          <b>19. Governing Law and Jurisdiction</b>
        </p>
      </div>
      <div>
        <div>
          <b>19.1. </b>These Terms shall be governed in accordance with the laws
          of the Republic of Cyprus.
        </div>
      </div>
      <div>
        <div>
          <b>19.2. </b>To the extent that any action relating to any Dispute
          hereunder is for whatever reason not submitted to arbitration (as per
          Section 18 above), each of the parties submits to the exclusive
          jurisdiction of the courts of the Republic of Cyprus to settle any
          Dispute that may arise out of or in connection with these Terms and/or
          the Services, and that accordingly proceedings must be brought in such
          courts.
        </div>
      </div>
      <div>
        <div>
          <b>19.3. </b>The parties irrevocably submit to the jurisdiction and
          venue of the courts of the Republic of Cyprus and waive any claims of
          improper venue or forum non conveniens.
        </div>
      </div>
      <div>
        <div>
          <b>19.4. </b>If you are an EEA or Switzerland user:
        </div>
        <p>
          Nothing in these Terms shall deprive you of the protection afforded to
          consumers by the mandatory rules of law of the country in which you
          live.
        </p>
        <p>
          You agree that the Services, Terms, and any Disputes between you and
          the Company shall be governed in all respects by the laws of the
          Republic of Cyprus, without regard to choice of law provisions.
        </p>
      </div>

      <div>
        <p>
          <b>20. Miscellaneous</b>
        </p>
      </div>
      <div>
        <div>
          <b>20.1. Cause of action. </b>Any claim or cause of action you may
          have with respect to your use of the Services must be commenced within
          one year after the claim or cause of action arises.
        </div>
      </div>
      <div>
        <div>
          <b>20.2. Severability and Enforceability. </b>If for any reason a
          court of competent jurisdiction finds any provision of the Terms, or a
          portion thereof, to be unenforceable, that provision shall be enforced
          to the maximum extent permissible so as to affect the intent of the
          Terms and the remainder of the Terms shall continue in full force and
          effect. A printed version of the Terms shall be admissible in judicial
          or administrative proceedings.
        </div>
      </div>
      <div>
        <div>
          <b>20.3. No waiver. </b>No waiver by FOODZEE of any term or condition
          set forth in the Terms shall be deemed a further or continuing waiver
          of such term or condition or a waiver of any other term or condition,
          and any failure of FOODZEE to assert a right or provision under the
          Terms shall not constitute a waiver of such right or provision.
        </div>
      </div>
      <div>
        <div>
          <b>20.4. Assignment. </b>FOODZEE reserves the right to transfer or
          assign its rights and obligations under these Terms to any party, by
          any method, including novation. By accepting these Terms, you agree to
          such transfers and assignments. Posting online an updated version of
          these Terms that identifies another party as a participant will serve
          as valid notice of FOODZEE transfer of rights and obligations, unless
          otherwise stated.
        </div>
      </div>
      <div>
        <div>
          <b>20.5. Electronic Communications. </b>All communications through the
          Services are considered as electronic communications, unless otherwise
          stated. When you interact with us through the Services or other
          electronic means, including without limitation email, you are
          communicating electronically. You agree that we may communicate with
          you electronically and that these communications, including notices,
          disclosures, agreements, and other documents and messages, have the
          same legal effect as if they were provided to you in writing. You also
          consent to our use of third-party providers to manage these electronic
          communications securely and efficiently. By clicking buttons labeled
          “Submit,” “Continue,” “Register,” “I Agree,” or similar, you are
          providing a binding electronic signature and entering into a legally
          binding contract. Your electronic submissions indicate your agreement
          to be bound by these Terms.{' '}
          <b>
            You consent to the use of electronic signatures, contracts, orders,
            and other records, and to the electronic delivery of notices,
            policies, and transaction records through the Services.
          </b>
        </div>
      </div>
      <div>
        <div>
          <b>20.6. Force Majeure. </b>FOODZEE will not be held liable for any
          failure to comply with these Terms if such failure results from
          circumstances beyond FOODZEE’s reasonable control.
        </div>
      </div>
      <div>
        <div>
          <b>20.7. Legal Guarantee. </b>Under European consumer laws, users in
          Europe are entitled to a legal guarantee that FOODZEE’s Services
          throughout the subscription duration are: a) in conformity with the
          contract, b) fit for the purpose for which they would normally be
          used, and c) of satisfactory quality and performance. FOODZEE hereby
          confirms that it complies with all of the above guarantees.
        </div>
      </div>
      <div>
        <div>
          <b>20.8. Termination. </b>Upon termination, all provisions of the
          Terms which by their nature should survive termination shall survive
          termination, including, without limitation, ownership provisions,
          warranty disclaimers, and limitations of liability.
        </div>
      </div>
      <div>
        <div>
          <b>20.9. Translations. </b>Any translation of the English version of
          these Terms is provided solely for your convenience. In case of any
          differences between the English version and any other translation, the
          English version shall prevail and shall be the only legally binding
          version.
        </div>
      </div>

      <div>
        <p>
          <b>21. Notice and Takedown Procedures</b>
        </p>
        <p>
          If you believe any materials accessible on or from the Services
          infringe your copyright, you may request the removal of those
          materials (or access thereto) from the Services by contacting the
          Company and providing the following information:
        </p>
        <ul>
          {[
            'Identification of the copyrighted work that you believe to be infringed. Please describe the work, and where possible include a URL, copy, or the location (e.g., application page) of an authorized version of the materials.',
            'Identification of the material that you believe to be infringing and its location. Please describe the material and provide us with its URL or any other pertinent information that will allow us to locate the material.',
            'Your name, address, and e-mail address.',
            'A statement that you have a good faith belief that the use of the materials in the manner complained of is not authorized by the copyright owner, its agent, or the law.',
            'A statement that the information that you have supplied is accurate, and indicating that “under penalty of perjury,” you are the copyright owner or are authorized to act on the copyright owner’s behalf.',
            'A signature or the electronic equivalent from the copyright holder or authorized representative.',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>

      <div>
        <p>
          <b>22. Questions and Comments</b>
        </p>
        <p>
          If you have any comments or questions regarding any part of the
          Services or the Terms, please feel free to contact us at{' '}
          <a href='mailto:support@foodzee.tech'>support@foodzee.tech.</a>
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;
