// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__Waaub {
  display: flex;
  flex-direction: column;
  gap: 32px;
  font-family: SF Pro Rounded;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(54, 69, 86, 0.6);
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .styles_container__Waaub {
    padding: 5px;
  }
}
.styles_container__Waaub b {
  font-weight: 700;
  color: #000000;
}
.styles_container__Waaub .styles_footnote__99szk {
  font-size: 13px;
  font-weight: 500;
  line-height: 15.51px;
}
.styles_container__Waaub h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 35.8px;
  letter-spacing: -0.3px;
  color: #000000;
}
.styles_container__Waaub a {
  color: #000000;
}
.styles_container__Waaub .styles_grayText__K3n2u {
  color: rgba(54, 69, 86, 0.6);
}
.styles_container__Waaub ul {
  padding-left: 27px;
}
.styles_container__Waaub ul li::marker {
  color: rgba(54, 69, 86, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/terms-of-use/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,4BAAA;EACA,aAAA;AACF;AACE;EAZF;IAaI,YAAA;EAEF;AACF;AAAE;EACE,gBAAA;EACA,cAAA;AAEJ;AACE;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,4BAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAMM;EACE,4BAAA;AAJR","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  font-family: SF Pro Rounded;\n  font-size: 17px;\n  font-weight: 400;\n  line-height: 24px;\n  text-align: left;\n  color: #36455699;\n  padding: 20px;\n\n  @media screen and (max-width: 768px) {\n    padding: 5px;\n  }\n  \n  b {\n    font-weight: 700;\n    color: #000000;\n  }\n\n  .footnote {\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 15.51px;\n  }\n\n  h1 {\n    font-size: 30px;\n    font-weight: 700;\n    line-height: 35.8px;\n    letter-spacing: -0.3px;\n    color: #000000;\n  }\n\n  a {\n    color: #000000;\n  }\n\n  .grayText {\n    color: #36455699;\n  }\n\n  ul {\n    padding-left: 27px;\n\n    li {\n      &::marker {\n        color: #36455699;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__Waaub`,
	"footnote": `styles_footnote__99szk`,
	"grayText": `styles_grayText__K3n2u`
};
export default ___CSS_LOADER_EXPORT___;
