import React from 'react';
import { ReactComponent as HeaderLogo } from './assets/headerLogo.svg';
import phoneMockup from './assets/mobile.png'; 
import './App.scss';

const App = () => {
  return (
    <div className="container">
      <main className='main-frame'>
        <header className="header">
          <div className="header-left">
            <HeaderLogo/>
            <div className="logo">FoodZee</div>
          </div>

          <div className="header-right">
            <div className="contactBubble">
              Feel free to contact us if you are looking for some help or partnership
            </div>
            <button className="contactButton">info@foodzee.tech</button>
          </div>
        
        </header>

        <main className="mainContent">
          <div className="textContent">
            Your Personal AI Assistant for Weight Loss and Balanced Nutrition
          </div>
          <img src={phoneMockup} alt="Phone Mockup" className="phoneMockup" />
        </main>

        <footer className="footer">
          <div className="footer-left">
            <span className="footer-left-first">POFKARIO HOLDINGS LIMITED</span>
            <span className="footer-left-second">Thessalonikis 43, office/flat 301 Thessaloniki Court, 3025 Limassol, Cyprus</span>
          </div>

          <div className="footer-right">
            <span className="footer-right-first">info@foodzee.tech</span>
            <span className="footer-right-second">+357 99 658 229</span>
          </div>
        </footer>
      </main>
    </div>
  );
};

export default App;