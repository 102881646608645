
//@ts-nocheck
//@ts-ignore
/* eslint-disable */
import React from 'react';
import styles from './styles.module.scss';

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <div>
        <p className={styles.footnote}>Effective date: December 3, 2024</p>
        <h1>Privacy Policy</h1>
      </div>

      <div>
        <p>
          This Privacy Policy explains how POFKARIO HOLDINGS LIMITED, a Cyprus
          company with registration no. ΗΕ 467843 and registered address
          Thessalonikis 43, office/flat 301 Thessaloniki Court, 3025 Limassol,
          Cyprus (referred to as “FoodZee”, “Company”, “us”, or “we”) collects,
          stores, uses, and protects your personal data in connection with your
          use of our mobile application “FoodZee” (the “App”), our website (
          <a href='https://foodzee.tech/' className={styles.grayText}>
            foodzee.tech
          </a>
          ), and all related services, features, and content (collectively the
          “Services”). This Privacy Policy also explains the rights you have in
          respect of the personal data we hold about you and the practices we
          implement to protect your privacy.
        </p>

        <p>
          For the purposes of this Privacy Policy, FoodZee acts as the Data
          Controller of your personal data.
        </p>
        <p>
          Any translation of the English version of this Privacy Policy is
          provided solely for your convenience. In case of any differences
          between the English version and any other translation, the English
          version shall prevail and shall be the only legally binding version.
        </p>
        <p>
          If you do not want us to process your personal data as described in
          this Privacy Policy, please do not use our Services.
        </p>
      </div>

      <div>
        <b>1. WHAT PERSONAL DATA WE COLLECT AND WHY</b>
        <p>
          When you use our Services, we collect the following data about you:
        </p>

        <div className={styles.tableWrapper}>
          <table>
            {[
              [
                'Purpose of collection and processing',
                'Data categories',
                'Collection means',
                'Legal basis for collection and processing',
              ],
              [
                'To provide core features of the App: AI chat-assistant, meal photo analysis, personalized nutrition plans',
                'Unique user ID, username, meal photos, dietary preferences, weight, height, health conditions, age, gender, activity patterns, device data',
                'You provide it to us or collected automatically',
                'Contract, Consent',
              ],
              [
                'To verify user eligibility for premium features',
                'Payment data, subscription data',
                'You provide it to us',
                'Contract, Legal obligation',
              ],
              [
                'To personalize AI recommendations',
                'Data shared in chats, onboarding responses, meal preferences',
                'You provide it to us or collected automatically',
                'Legitimate interest, Consent',
              ],
              [
                'To create and manage your account',
                'Name, email address, age, country, device data',
                'You provide it to us',
                'Contract',
              ],
              [
                'To send technical notices and updates',
                'Email address',
                'You provide it to us',
                'Legitimate interest',
              ],
              [
                'For research and product improvement',
                'App usage data, anonymized chat data',
                'Collected automatically',
                'Legitimate interest',
              ],
            ].map((row, rowIdx, thisArr) => {
              return (
                <tr key={rowIdx}>
                  {row.map((col, colIdx) => {
                    return rowIdx ? (
                      <td key={`${rowIdx}${colIdx}`}>{col}</td>
                    ) : (
                      <th key={`${rowIdx}${colIdx}`}>{col}</th>
                    );
                  })}
                </tr>
              );
            })}
          </table>
        </div>

        <p>
          Note about payment data: When you make payments through the Services,
          your payment information (e.g., credit card number) is handled by
          third-party payment processors. We do not store full credit card
          details.
        </p>
      </div>

      <div>
        <b>2. PERSONAL DATA RETENTION</b>
        <p>
          We retain your personal data for as long as your account is active or
          as needed for the purposes of processing. You may delete your account
          and associated data at any time through the App settings or by
          contacting us at support@foodzee.tech. Please note, certain data may
          be retained as required by law or for dispute resolution purposes.
        </p>
      </div>

      <div>
        <b>3. YOUR DATA PROTECTION RIGHTS</b>
        <p>
          If you are an EEA/UK resident, you have rights under the GDPR,
          including:
        </p>
        <ul>
          {[
            'Access to and portability of your data',
            'Correction and deletion of your data',
            'Restriction or objection to processing',
            'Withdrawal of consent for specific purposes',
            'Filing complaints with your local data protection authority',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>
          To exercise these rights, contact us at{' '}
          <a href='mailto:support@foodzee.tech'>support@foodzee.tech.</a>
        </p>
      </div>

      <div>
        <b>4. SECURITY MEASURES</b>
        <p>
          We implement technical and organizational measures to protect your
          personal data, including:
        </p>
        <ul>
          {[
            'Encryption',
            'Systematic vulnerability scanning',
            'Access control policies',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>
          Despite our efforts, no security measures can guarantee complete
          safety. Any data transmission is at your own risk.
        </p>
      </div>

      <div>
        <b>5. CHILDREN’S PRIVACY</b>
        <p>
          Our Services are not directed to individuals under 18 years of age. If
          we learn that we have collected personal data from a child under 18,
          we will delete it promptly. Parents or guardians may contact us at{' '}
          <a href='mailto:support@foodzee.tech'>support@foodzee.tech</a> if they
          believe their child has provided us with personal data.
        </p>
      </div>

      <div>
        <b>6. SHARING OF YOUR PERSONAL DATA</b>
        <p>We may share your data with:</p>
        <ul>
          {[
            'Affiliates and subsidiaries',
            'Service providers (e.g., cloud storage, email delivery, analytics tools)',
            'Payment processors',
            'Regulatory authorities, where legally required',
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>

      <div>
        <b>7. CROSS-BORDER DATA TRANSFERS</b>
        <p>
          Your data may be transferred and processed outside the EEA, where it
          may be governed by different data protection laws. We ensure
          appropriate safeguards (e.g., Standard Contractual Clauses) are in
          place for such transfers.
        </p>
      </div>

      <div>
        <b>
          8. COOKIES, SOFTWARE DEVELOPMENT KITS, AND OTHER TRACKING TECHNOLOGIES
        </b>

        <p>
          We use cookies and similar technologies to provide and improve our
          Services. For more information, please see our Cookie Notice on{' '}
          <a href='https://foodzee.tech/'>foodzee.tech.</a>
        </p>
      </div>

      <div>
        <b>9. YOUR CHOICES ABOUT OUR COMMUNICATIONS WITH YOU</b>
        <p>
          You may opt out of marketing communications by following the
          “Unsubscribe” a in our emails.
        </p>
      </div>

      <div>
        <b>10. CONTACT US</b>
        <p>
          If you have questions or concerns about this Privacy Policy, please
          contact us:
        </p>
        <ul>
          <li>
            Email:{' '}
            <a href='mailto:support@foodzee.tech'>support@foodzee.tech</a>
          </li>
          <li>
            Address: Thessalonikis 43, office/flat 301 Thessaloniki Court, 3025
            Limassol, Cyprus
          </li>
        </ul>
      </div>

      <div>
        <b>11. CHANGES TO OUR PRIVACY POLICY</b>
        <p>
          We may update this Privacy Policy from time to time. Significant
          changes will be communicated to you via the App or email. The latest
          version is always available on our website.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
