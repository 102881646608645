// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_card__E90fP {
  width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.styles_card__E90fP .styles_cardTitle__vf7uH {
  color: #e5cefb;
  font-family: "SF Pro Rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 106%;
  letter-spacing: -0.3px;
  margin-bottom: 42px;
}
.styles_card__E90fP .styles_cardTitle__vf7uH span {
  color: #FFF;
  font-feature-settings: "case" on;
  font-family: "SF Pro Rounded";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
  margin-right: 6px;
}
.styles_card__E90fP .styles_image__GWmqu {
  width: 310px;
}
@media screen and (max-width: 768px) {
  .styles_card__E90fP {
    margin-bottom: 20px;
  }
}

.styles_mainTitle__Tu2pG {
  color: #FFF;
  font-family: "SF Pro Rounded";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 106%; /* 42.4px */
  letter-spacing: -0.3px;
  margin: 40px 0 45px 80px;
}
@media screen and (max-width: 768px) {
  .styles_mainTitle__Tu2pG {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/delete-account/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;AACF;AACE;EACE,cAAA;EACA,6BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACE,WAAA;EACA,gCAAA;EACA,6BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,wBAAA;EACA,iBAAA;AACN;AAGE;EACE,YAAA;AADJ;AAIE;EAlCF;IAmCI,mBAAA;EADF;AACF;;AAIA;EACE,WAAA;EACA,6BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,sBAAA;EACA,wBAAA;AADF;AAGE;EAVF;IAWI,YAAA;IACA,gBAAA;IACA,mBAAA;EAAF;AACF","sourcesContent":[".card {\n  width: 210px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n\n  .cardTitle {\n    color: #e5cefb;\n    font-family: \"SF Pro Rounded\";\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 106%;\n    letter-spacing: -0.3px;\n    margin-bottom: 42px;\n\n    span {\n      color: #FFF;\n      font-feature-settings: 'case' on;\n      font-family: \"SF Pro Rounded\";\n      font-size: 17px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: 22px;\n      letter-spacing: -0.408px;\n      margin-right: 6px;\n    }\n  }\n\n  .image {\n    width: 310px;\n  }\n\n  @media screen and (max-width: 768px) {\n    margin-bottom: 20px;\n  }\n}\n\n.mainTitle {\n  color: #FFF;\n  font-family: \"SF Pro Rounded\";\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 106%; /* 42.4px */\n  letter-spacing: -0.3px;\n  margin: 40px 0 45px 80px;\n\n  @media screen and (max-width: 768px) {\n    margin: auto;\n    margin-top: 20px;\n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `styles_card__E90fP`,
	"cardTitle": `styles_cardTitle__vf7uH`,
	"image": `styles_image__GWmqu`,
	"mainTitle": `styles_mainTitle__Tu2pG`
};
export default ___CSS_LOADER_EXPORT___;
